import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { UserService } from '../services/user.service';
import { BehaviorSubject, Observable, filter, switchMap, take } from 'rxjs';
import { User } from '../interfaces/user.interface';
import { Device } from '../interfaces/device.interface';
import { DeviceService } from '../services/device.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-settings',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  @Output() devicesChanges = new EventEmitter<Device[]>();
  usersSubject = new BehaviorSubject<User[]>([]);
  users$ = this.usersSubject.asObservable();
   devicesSubject = new BehaviorSubject<Device[]>([]);
   devices$ = this.devicesSubject.asObservable();
  isNewUserFormVisible = false; 
  isNewDeviceFormVisible = false; 
  newUser:User = { name: '', email: '', nexusadmin: false };
  newDevice: Device = { name: '', uniqueid: '' };
  editUserId: number | null = null;
  editDeviceId: number | null = null;
  uniqueIdError: boolean = false;
  emailError: boolean = false;
  constructor(private userService: UserService, private deviceService: DeviceService, private cdr: ChangeDetectorRef, private authService: AuthService) { }
  
  ngOnInit(): void {
    this.getUsers();
    this.getDevices();
  }


//======================
//user block
//
//======================
  toggleEditUser(user: User) {
    if (user.id) {
      this.editUserId = this.editUserId === user.id ? null : user.id;
     this.getUsers();

    }
  }
  toggleNewUserForm() {
    this.isNewUserFormVisible = !this.isNewUserFormVisible;
  }
  getUsers() {
    this.userService.getUsers().pipe(
      take(1)
    ).subscribe(users => {
      this.usersSubject.next(users);
    });
  }
  
  addNewUser() {
    const user = localStorage.getItem('user');
    if (user && this.newUser.email && this.newUser.name && this.newUser.password) {
      const userObj = JSON.parse(user);
      this.newUser.company_id = userObj.company_id;

      this.authService.checkEmailInUse(this.newUser.email).pipe(take(1)).subscribe((res:any) => {
        this.emailError = res.inUse;
        console.log(res);
        
        if (this.emailError) {
          console.log('El email ya está en uso');
          this.cdr.detectChanges();
          return;
        }

      this.userService.create(this.newUser).pipe(
        switchMap((res) => this.userService.getUsers())
      ).subscribe(users => {
        this.usersSubject.next(users);
        this.newUser = { name: '', email: '', nexusadmin: false };
        this.toggleNewUserForm();
      });

    })
  }

  }


  updateUser(user: User) {
    
/*     if (user.email) {
      this.authService.checkEmailInUse(user.email).subscribe((res: any) => {
        this.emailError = res.inUse;
        if (this.emailError) {
          console.log('El email ya está en uso');
          this.cdr.detectChanges();
          setTimeout(() => { this.emailError = false; this.cdr.detectChanges(); }, 3000);
          return;
        }
   
      })
    } */
    if (user.id) {
      this.userService.update(user.id, user).pipe(take(1)).subscribe(updatedUser => {
        const currentUsers = this.usersSubject.getValue();
        const updatedUsers = currentUsers.map(u => u.id === user.id ? updatedUser : u);
        this.usersSubject.next(updatedUsers);
        this.editUserId = null;
      });
    }

  }
  deleteUser(user: User) {
    if (user.id) {
      this.userService.delete(user.id).pipe(take(1)).subscribe(res => {
        this.usersSubject.next(this.usersSubject.getValue().filter(u => u.id !== user.id));
      });
    }

  }

//========================================
//End user block
//========================================

//======================
//device block
//
//======================



  toggleNewDeviceForm() {
    this.isNewDeviceFormVisible = !this.isNewDeviceFormVisible;
  }
  toggleEditDevice(device: Device) {
    if (device.id) {
      this.editDeviceId = this.editDeviceId === device.id ? null : device.id;
    }
  }
  getDevices() {
    this.deviceService.getDevices().pipe(take(1)).subscribe(devices => {
      return this.devicesSubject.next(devices)
    })
  }


addNewDevice() {
  const user = localStorage.getItem('user');
  if (user) {
    const userObj = JSON.parse(user);
    this.newDevice.company_id = userObj.company_id;

    if (this.newDevice.uniqueid) {
      // Verifica si el uniqueid está disponible
      this.deviceService.checkDeviceIdAvailable(this.newDevice.uniqueid).subscribe((res) => {
        if (res==true) {
          this.deviceService.create(this.newDevice).pipe(
            switchMap(() => this.deviceService.getDevices())
          ).subscribe(devices => {
            console.log(devices);
            this.devicesSubject.next(devices);
            this.newDevice = { name: '', uniqueid: '', company_id: userObj.company_id };
            this.toggleNewDeviceForm();
            this.uniqueIdError = false;
            this.devicesChanges.emit(devices);
          });
        } else {
          // Muestra un mensaje de error si el uniqueid no está disponible
          console.log('El uniqueid no está disponible');
          
        this.uniqueIdError = true;
          this.cdr.detectChanges(); 
        }
      });
    } else {
      // Muestra un mensaje de error si el uniqueid no está definido
      console.log('El uniqueid no está definido');
    }
  }
}


  updateDevice(device: Device) {
    if (device.id) {
      const deviceid = device.id.toString();
      this.deviceService.update(deviceid, device).pipe(take(1)).subscribe(updatedDevice => {
        const currentDevices = this.devicesSubject.getValue();
        const updatedDevices = currentDevices.map(d => d.id === device.id ? updatedDevice : d);
        this.devicesSubject.next(updatedDevices);
        this.editDeviceId = null;
        this.devicesChanges.emit(this.devicesSubject.getValue());
      });
    }
  }
  deleteDevice(device: Device) {
    if (device.id) {
      const deviceid = device.id.toString();
      this.deviceService.delete(deviceid).pipe(take(1)).subscribe(res => {
        this.devicesSubject.next(this.devicesSubject.getValue().filter(d => d.id !== device.id));
      });
      this.devicesChanges.emit(this.devicesSubject.getValue());
    }
  }

//========================================
//End device block block
//========================================
}
