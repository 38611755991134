import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;

  constructor(private authService: AuthService, private router:Router) {
    this.registerForm = new FormGroup({
      'name': new FormControl("", Validators.required),
      'password': new FormControl("", [Validators.required, Validators.minLength(6)]),
      'company': new FormControl(null),
      'email': new FormControl("", [Validators.required, Validators.email,])
    });
  }

  ngOnInit() {

  }

  onSubmit() {
    if (this.registerForm.valid) {
      const valid = this.emailValidator(this.registerForm.value.email).then((res) => {
        if (res == true) {
          this.authService.register(this.registerForm.value).subscribe((res) => {
          
            this.router.navigateByUrl("/")
          })
        }
      })
    }
  }

  emailValidator(control: FormControl): Promise<any> | Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.authService.checkEmailInUse(control.value).subscribe(isTaken => {
        if (isTaken) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  }
}
