import { NgModule } from '@angular/core';
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbButtonModule,
  NbIconModule,
  NbCardModule,
  NbProgressBarModule,
  NbMenuModule,
  NbPopoverModule,
  NbListModule,
  NbInputModule,
  NbActionsModule,
  NbTabsetModule,
  NbDatepickerModule,
  NbSelectModule,
  NbDialogModule,
  NbTimepickerModule,
} from '@nebular/theme';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SiNoPipe } from '../pipes/si-no.pipe';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { PlacesAutocompleteComponent } from './places-autocomplete/places-autocomplete.component';
@NgModule({
  declarations: [SiNoPipe, TimepickerComponent, PlacesAutocompleteComponent],
  imports: [
    CommonModule,
    FormsModule,
    CommonModule,
    NbLayoutModule,
    NbButtonModule,
    NbEvaIconsModule,
    NbIconModule,
    NbCardModule,
    GoogleMapsModule,
    NbProgressBarModule,
    NbPopoverModule,
    NbListModule,
    HttpClientModule,
    NbInputModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbTimepickerModule,
    NbTabsetModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    NbThemeModule,
    NbLayoutModule,
    NbSidebarModule,
    NbButtonModule,
    NbEvaIconsModule,
    NbIconModule,
    NbCardModule,
    GoogleMapsModule,
    NbProgressBarModule,
    NbMenuModule,
    NbPopoverModule,
    NbListModule,
    HttpClientModule,
    NbInputModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbDatepickerModule,
    SiNoPipe,
    TimepickerComponent,
    PlacesAutocompleteComponent,
    NbTabsetModule
  ],
})
export class SharedModule {}
