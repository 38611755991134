


<div class="row">
  <div class=" col-sm-6">
    <label for="hour">Hora:</label>
    <select class="form-control" id="hour" name="hour" [(ngModel)]="selectedHour" [disabled]="isAllDayChecked" (change)="emitTime()" >
      <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
    </select>
  </div>
  <div class=" col-sm-6">
    <label for="minutes">Minutos:</label>
    <select class="form-control" id="minutes" name="minutes" [(ngModel)]="selectedMinute" [disabled]="isAllDayChecked"(change)="emitTime()" >
      <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
    </select>
  </div>
</div>

<div class="form-check">
  <input class="form-check-input" type="checkbox" id="allDayCheckbox" [(ngModel)]="isAllDayChecked" (change)="onAllDayCheckedChange()" >
  <label class="form-check-label" for="allDayCheckbox">Todo el día</label>
</div>
