
<div class="container-fluid">
      <div class="row m-2">
        <div class="col">
            <h2>Historico</h2>
        </div>
    </div>
<div class="row">
  <div class="col-sm"   [nbSpinner]="loading"
      nbSpinnerStatus="danger"
             nbSpinnerSize="large">

  
   <google-map
  width="100%"
   height="80vh"
  [center]="center"
  [options]="options"
 >

  <map-marker
  *ngFor="let marker of markers; let i = index"
  #marker1="mapMarker"
  [position]="marker?.position"
   [label]="marker?.label"
  [options]="marker?.options"
 (mapClick)="openInfo(marker1, marker)"
>

 <map-info-window #info="mapInfoWindow">
        <div class="d-flex justify-content-center">
          <h4>
            <strong>  {{ selectedMarker?.info?.pos }}</strong>
          </h4>
        </div>
      
          <div>
            <ul class="list-group">
          
              <li class="list-group-item">
                Movimiento: {{ selectedMarker?.info?.attributes?.motion }}
              </li>
              <li class="list-group-item">
                hora :{{
                  selectedMarker?.info?.fixtime 
                    | date : "HH:mm"
                }}
              </li>
              <li class="list-group-item">
                Velocidad: {{ selectedMarker?.info?.speed }} km/h
              </li>
            </ul>
          </div>
       
    

        <div class="d-flex justify-content-between m-2">
          <button nbButton status="danger" size="small" (click)="closeInfo()">
            cerrar
          </button>
   
        </div>
      </map-info-window>

</map-marker> 

  

 <map-polyline
    [path]="routePath"
    [options]="{ strokeColor: '#37eb34', strokeOpacity: 1.0, strokeWeight: 2 }"
  ></map-polyline> 


 
</google-map>


  <div class="row m-0 p-0 mb-2" style="position: absolute; bottom: 0; left: 0; width: 100%;" [ngClass]="{'minimized': isMinimized}">
      <div class="row m-0 p-0">
  <div class="col-12 text-center" >
  <ng-container *ngIf="!isMinimized; else maximized">
<button nbButton  (click)="toggleMinimize()" status="primary"><nb-icon icon="arrowhead-down-outline" ></nb-icon></button>
  </ng-container>
  <ng-template #maximized>
 <button nbButton  (click)="toggleMinimize()"status="primary"><nb-icon icon="arrowhead-up-outline" ></nb-icon></button>
  </ng-template>
  
  </div>
   </div>
     <div class="row d-flex justify-content-start mb-2 align-items-center" style="background-color: aliceblue;" >
     <div class="col-sm d-flex align-items-center m-2">
      <nb-select placeholder="Dispositivos" size="small" [(ngModel)]="selectedDevice"  required>
        <nb-option *ngFor="let d of devices;" [value]="d">{{d.name}}</nb-option>
      </nb-select>
    </div>
    <div class="col-sm d-flex align-items-center m-2">
      <input nbInput placeholder="Form Picker" [nbDatepicker]="ngmodel" [(ngModel)]="ngModelDate">
        <nb-datepicker #ngmodel></nb-datepicker>
    </div>
    <div class="col-sm d-flex   justify-content-end m-2">
         <button nbButton status="primary" size="small" [disabled]="!selectedDevice " (click)="searchRoute()" class="m-1">Buscar</button>
            <ng-container *ngIf="finishLoop==true; else finishButton">
         <button nbButton status="success" size="small" [disabled]="positions.length<1 " (click)="PlayRoute()" class="m-1">reproducir ruta<nb-icon icon="play-circle-outline"  ></nb-icon></button>

       </ng-container>
       <ng-template #finishButton>
          <button nbButton status="danger" size="small" [disabled]="positions.length<1 " (click)="stopRoute()" class="m-1">detener ruta<nb-icon icon="stop-circle-outline"  ></nb-icon></button>
       </ng-template>
   
     
    </div>
  </div>
 

 <div class="row m-0 p-0 tableHeight">
        <ng-container *ngIf="positions.length>0; else noPositions">
    
<table class="table table-borderless ">
  <thead>
    <tr>
      <th scope="col">Posición</th>
      <th scope="col">Hora</th>
      <th scope="col">Detalles</th>
      <th scope="col">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let p of positions; let i = index">
      <td>{{i }}</td>
      <td>{{ p.fixtime | date:"hh:mm"  }}</td>
      <td>
        movimiento:{{ p.attributes?.motion | siNo  }}
      </td>
       <td>
       <button nbButton (click)="goToMarker(p)">Ver</button>
       <ng-container *ngIf="finishLoop==true; else finishButton">
         <button nbButton status="success" size="small" [disabled]="positions.length<1 " (click)="PlayRoute(i)" class="m-1"><nb-icon icon="play-circle-outline"  ></nb-icon></button>

       </ng-container>
       <ng-template #finishButton>
         <button nbButton status="danger" size="small" [disabled]="positions.length<1 " (click)="stopRoute()" class="m-1"><nb-icon icon="stop-circle-outline"  ></nb-icon></button>
        
       </ng-template>
       
       

          
      </td>
    </tr>
  </tbody>
</table>
</ng-container>
<ng-template #noPositions>

    <div class="alert alert-warning text-center" role="alert">
      No hay posiciones para mostrar
    </div>

</ng-template>
   </div>





</div>

  </div>
</div>






</div>
