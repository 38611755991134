import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from '../services/user.service';
import { TaskService } from '../services/task.service';
import { User } from '../interfaces/user.interface';
import { Task } from '../interfaces/task.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-driver-dasboard',
  templateUrl: './driver-dasboard.component.html',
  styleUrls: ['./driver-dasboard.component.scss']
})
export class DriverDasboardComponent  {
  selectedDate!: string; // cambiar a string
  user$!: Observable<User>;
  tasks$!: Observable<Task[]>;

  constructor( private userService:UserService, private  taskService:TaskService) { 
    this.selectedDate = new Date().toISOString().split('T')[0];
  }
  ngOnInit(): void {
    this.user$ = this.getUser()
    this.tasks$ = this.getTasks()
  }


  getUser(){
    return this.userService.getUser()
  }

getTasks(){
  console.log(this.selectedDate);
  const date = new Date(this.selectedDate)
  return this.taskService.getMany(date)
}

  onDateChange() {
    console.log(this.selectedDate);
    this.tasks$ = this.getTasks()
    // Puedes agregar aquí cualquier lógica adicional que necesites con la fecha seleccionada
  }

  loadRoute(task:Task): void {
    console.log(task.address);
    
  window.open(`https://www.google.com/maps?q=${task.address}`, '_blank');
  }
}
