<div class="container-fluid">
  <div class="row m-2">
    <div class="col">
      <h2>Mapa</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12"
     [nbSpinner]="loading"
      nbSpinnerStatus="danger"
             nbSpinnerSize="large">
    <google-map
        width="100%"
        height="80vh"
        [center]="center"
        [options]="options"
        (mapClick)="click($event)"
      >
        <map-marker
          *ngFor="let marker of markers; let i = index"
          #marker1="mapMarker"
          [position]="marker?.position"
          [label]="marker?.label"
          [title]="marker?.title"
          [options]="marker?.options"
          (mapClick)="openInfo(marker1, marker)"
        >
        </map-marker>

        <map-marker
          *ngFor="let marker of taskMarkers; let i = index"
          #marker2="mapMarker"
          [position]="marker?.position"
          [label]="marker?.label"
          [title]="marker?.title"
          [options]="marker?.options"
          (mapClick)="openTaskInfo(marker2, marker)"
        >
     
        </map-marker>

          <map-info-window #info="mapInfoWindow">
            <div class="d-flex justify-content-center">
              <h4>
                <strong>{{ selectedMarker?.title }}</strong>
              </h4>
            </div>
  
              <div>
                <ul class="list-group">
                  <li class="list-group-item">
                   <strong> Conexion:</strong> {{ selectedMarker?.info.status }}
                  </li>
                  <li class="list-group-item">
                <strong>    Movimiento: </strong>{{ selectedMarker?.info.motionstate | siNo }}
                  </li>
                  <li class="list-group-item">
                 <strong>   Fecha :</strong>{{
                      selectedMarker?.info.position.servertime
                        | date : "dd/MM/yyyy HH:mm"
                    }}
                  </li>
                  <li class="list-group-item">
                   <strong> Velocidad: </strong>{{ selectedMarker?.info.position.speed }} km/h
                  </li>
                </ul>
              </div>
        
          

            <div class="d-flex justify-content-between m-2">
              <button
                nbButton
                status="danger"
                size="small"
                (click)="closeInfo()"
              >
                cerrar
              </button>
              <button
                *ngIf="selectedMarker?.info.uniqueid"
                nbButton
                status="danger"
                size="small"
                (click)="showHistory(selectedMarker)"
              >
                historial
              </button>
            </div>
          </map-info-window>

             <map-info-window #info2="mapInfoWindow">
            <div class="d-flex justify-content-center">
              <h4>
                <strong>{{ selectedMarker?.title }}</strong>
              </h4>
            </div>
      
         
              <div>
                <ul class="list-group">
                  <li class="list-group-item">
                    Descripcion: {{ selectedMarker?.info.description }}
                  </li>
                  <li class="list-group-item">
                    Direccion: {{ selectedMarker?.info.address }}
                  </li>
                  <li class="list-group-item">
                    Fecha :{{
                      selectedMarker?.info.date | date : "dd/MM/yyyy HH:mm"
                    }}
                  </li>
                  <li class="list-group-item">
                    hora: {{ selectedMarker?.info.hour }}
                  </li>
                  <li class="list-group-item">
                    Asignado a: {{ getUserNameById(selectedMarker?.info?.assigned_to) }}
                  </li>
                </ul>
              </div>
 

            <div class="d-flex justify-content-between m-2">
              <button
                nbButton
                status="danger"
                size="small"
                (click)="closeInfo()"
              >
                cerrar
              </button>
              <button
                *ngIf="selectedMarker?.info.done!==true"
                nbButton
                status="primary"
                size="small"
                (click)="completeTask(selectedMarker)"
              >
                Completar
              </button>
            </div>
          </map-info-window>

        <map-polyline
          *ngFor="let route of routePaths; let i = index"
          [path]="route"
          [options]="{
            strokeColor: colors[i % colors.length],
            strokeOpacity: 1.0,
            strokeWeight: 5
          }"
        >
        </map-polyline>
      </google-map> 

    </div>
  </div>

  <!--   <nb-progress-bar [value]="countDown" status="success">Refrescando...</nb-progress-bar> -->

  <div
    class="row m-0 p-0 mb-2"
    style="position: absolute; bottom: 0; left: 0; width: 100%"
    [ngClass]="{ minimized: isMinimized }"
  >
    <div class="row m-0 p-0">
      <div class="col-12 text-center">
        <ng-container *ngIf="!isMinimized; else maximized">
          <button nbButton (click)="toggleMinimize()" status="primary">
            <nb-icon icon="arrowhead-down-outline"></nb-icon>
          </button>
        </ng-container>
        <ng-template #maximized>
          <button nbButton (click)="toggleMinimize()" status="primary">
            <nb-icon icon="arrowhead-up-outline"></nb-icon>
          </button>
        </ng-template>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col-sm">
        <div class="card cardHeight">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 align-items-center">
                <strong>Dispositivos</strong>
             
              </div>
              <div class="col-sm-6 d-flex justify-content-end">
                       <button
                  nbButton
                  outline
                  shape="round"
                  status="primary"
                  appearance="ghost"
                  size="small"
                  (click)="goToDevices()"
                >
                  <nb-icon icon="plus-outline"></nb-icon>
                </button>
              </div>
               </div>
               <div class="row">
             <div class="col-sm legend">
           <div class="state active"></div>
           <div class="col-sm">
     Activo
          </div>
</div>

              <div class="col-sm  legend">
                <div class="state inactive"></div>
                <div class="col-sm">
                  Inactivo
                </div>
              </div>
                  <div class="col-sm  legend">
                <div class="state invalid"></div>
                <div class="col-sm">
                  invalido
                </div>
              </div>
           
          </div>
             </div>

          <ng-container *ngIf="devices.length > 0; else noDevices">
            <div class="card-body text-right">
              <ng-container  *ngFor="let d of devices">
   <button

                type="button"
                class="btn m-1"
     [ngClass]="{
    'btn-secondary': d.position?.valid== false,
    'btn-success': d.position?.valid != false && d.status == 'online',
    'btn-danger': d.position?.valid != false && (d.status === 'offline' || d.status === null || d.status === undefined)
  }"
                style="width: 100px"
                (click)="moveMapMethod(d)"
              >
                {{ d.name }}
     
              </button>
            
              </ng-container>
              
           
            </div>
          </ng-container>
          <ng-template #noDevices>
            <div class="card-body text-center">
              <div class="alert alert-warning" role="alert">
                No hay dispositivos
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-sm">
        <div class="card cardHeight">
          <div class="card-header">
            <div class="row">
              <div class="col d-flex align-items-center">
                <strong>Tareas  {{ date | date : "dd/MM/yy" }}</strong>
              </div>
                 <div class="col-sm-6 d-flex justify-content-end">
                    <button
                  nbButton
                  outline
                  shape="round"
                       status="primary"
                  appearance="ghost"
                  size="small"
                  (click)="goToTasks()"
                >
                  <nb-icon icon="plus-outline"></nb-icon>
                </button>
                </div>
                            <div class="row">
             <div class="col-sm legend">
           <div class="state active"></div>
           <div class="col-sm">
     Completadas
          </div>
</div>

              <div class="col-sm  legend">
                <div class="state inactive"></div>
                <div class="col-sm">
                  Pendientes
                </div>
              </div>
           
          </div>
            </div>
          </div>
          <div class="card-body text-right">
            <ng-container *ngFor="let t of tasks">
              <ng-container *ngIf="tasks.length > 0; else noTasks">
                <button
                  type="button"
                  class="btn btn-primary m-1"
                  [ngClass]="{'btn-success': t.done, 'btn-danger': !t.done}"
                  style="width: 100px"
                  (click)="moveMapMethod(t)"
                >
                  {{ t.name }}
                </button>
              </ng-container>
            </ng-container>

            <ng-template #noTasks>
              <div class="alert alert-warning" role="alert">
                No hay Tareas para hoy
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
