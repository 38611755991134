import {
  Component,
  Output,
  EventEmitter,
} from '@angular/core';
import { UserService } from '../services/user.service';
import { TaskService } from '../services/task.service';
import { Subject, switchMap, take, takeUntil } from 'rxjs';
import { Task } from '../interfaces/task.interface';
import { User } from '../interfaces/user.interface';


@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})


export class TasksComponent {
  buttonDisabled=false;
  isNewTaskFormVisible:boolean=false;
  task!:Task;
  oldTask!:Task;
  editTaskId: number | null = null;
  data: any[] = [];
  ngModelDate = new Date();
  user:any;
  tasks:Task[] | null = null;
  @Output() update = new EventEmitter<Task[]>();
  private unsubscribe$ = new Subject<void>();
  users!:User[]




  constructor( private userService:UserService, private taskService:TaskService){
    if (!this.task) {
      this.task = {
        date: new Date(),
        hour: "00:00",
        done: false,
       assigned_to: null
      };
    }
 this.getTasks()
 this.getUsers()
  }


  getUsers() {
    this.userService.getUsers().pipe(
      take(1)
    ).subscribe(users => {
      this.users = users;
    });
  }

  getUserNameById(id:number){
     const user = this.users.find(user=> user.id===id)
     if(user){
       return user.name 
     }

   return null
  }



  toggleNewTaskForm(){
    this.isNewTaskFormVisible=!this.isNewTaskFormVisible
  }
  toggleEditTask(task: Task) {

    if (task.id) {
      this.editTaskId = this.editTaskId === task.id ? null : task.id;
    
      this.getTasks();

    }
  }
  createTask() {
    console.log(this.task);
    
     this.taskService.create(this.task).subscribe(res => {
      this.getTasks(); 
      this.isNewTaskFormVisible = false;
       this.task = {
        date: new Date(),
        hour: "00:00",
        done: false,
        assigned_to:undefined
      };
    }) 
  }

  onExistingTaskTimeSelected(event: any, task:Task): void {
    if (event.hour == null || event.minute == null) {
      task.hour = null
    } else {
    task.hour = `${event.hour}:${event.minute}`
    }

    console.log('Hora seleccionada:', event);
    // Aquí puedes realizar cualquier acción con la hora seleccionada, como asignarla a una variable, etc.
  }

  onExistingTaskAddressSelected(event: any, task: Task): void {
    console.log(event);
    task.address = event.name;
    task.latitude = event.latitude;
    task.longitude = event.longitude;
  }





  onTimeSelected(event: any): void {
    if (event.hour == null || event.minute == null) {
      this.task.hour = null
    } else {
      this.task.hour = `${event.hour}:${event.minute}`

    }
    console.log('Hora seleccionada:', event);
    // Aquí puedes realizar cualquier acción con la hora seleccionada, como asignarla a una variable, etc.
  }


  onAddressSelected(event: any): void {
    console.log(event);
    this.task.address = event.name;
    this.task.latitude = event.latitude;
    this.task.longitude = event.longitude;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onDateChange(event: any): void {
    this.getTasks()
  }
  
  getTasks() {
    this.taskService.getMany(this.ngModelDate)
      .pipe(
        switchMap(res => {
          this.tasks = res;
          console.log(res);
          
          return res;
        }),
        takeUntil(this.unsubscribe$)  // Automatically unsubscribe when this.unsubscribe$ emits
      )
      .subscribe((res: any) => {
        this.update.emit(res);
      });
  }



  deleteTask(taskId: any ) {
    this.buttonDisabled=true
    if (taskId) {
      this.taskService.delete(taskId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          setTimeout(() => this.buttonDisabled = false, 1000);
          this.getTasks();  // Actualiza la lista de tareas después de borrar una tarea
        }); 
    }

  }

  toggleTaskStatus(task: any) {
    this.buttonDisabled = true;

    // Temporizador para volver a habilitar el botón después de 1 segundo
    
    task.done = !task.done; 
    this.taskService.update(task.id, task)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          setTimeout(() => this.buttonDisabled = false, 1000);
   this.getTasks()
        },
        error: (error) => {
          // Manejo de errores
          console.error('Error updating task status', error);
        }
      });
  
  }



  editTask(task: any) {
    console.log(task);
    
    this.taskService.update(task.id, task).subscribe(res=>{
      this.toggleEditTask(task)
      this.getTasks()
    })

  }


}
