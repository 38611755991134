import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './auth/auth.guard';
import { loggedGuard } from './auth/logged.guard';
import { PolicyComponent } from './policy/policy.component';
import { DriverDasboardComponent } from './driver-dasboard/driver-dasboard.component';




const routes: Routes = [
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canActivate: [loggedGuard] },
  {
    path: 'main', component: MainComponent, canActivate: [AuthGuard] 
  },
  {
    path: 'tasker', component: DriverDasboardComponent, canActivate: [AuthGuard]
  },
  { path: 'register', component: RegisterComponent },
  { path: 'policy', component: PolicyComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
