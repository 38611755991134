import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { Observable } from 'rxjs';
import { Device } from '../interfaces/device.interface';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private url = environment.url + "/devices";

  constructor(private http: HttpClient) { }

  private getHeaders() {
    const authToken = localStorage.getItem("token");
    return {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    };
  }

  create(device: Device): Observable<Device> {
    return this.http.post<Device>(this.url, device, this.getHeaders());
  }

  delete(deviceid: string): Observable<any> {
    const deleteUrl = `${this.url}/${deviceid}`;
    return this.http.delete(deleteUrl, this.getHeaders());
  }

  update(deviceid: string, updatedDevice: any): Observable<any> {
    const editUrl = `${this.url}/${deviceid}`;
    return this.http.put(editUrl, updatedDevice, this.getHeaders());
  }

  getDevice(device: number): Observable<Device> {
    return this.http.get<Device>(this.url + "?id=" + device, this.getHeaders());
  }

  getDevices(): Observable<Device[]> {
    return this.http.get<Device[]>(this.url + "/devices", this.getHeaders());
  }

  checkDeviceIdAvailable(id: string): Observable<boolean> {
    return this.http.get<boolean>(this.url + "/check/?id=" + id, this.getHeaders());
  }
}
