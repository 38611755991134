import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';



@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private apiUrl = environment.url +"/auth"
  private headers = new HttpHeaders({
  });
  constructor(private http: HttpClient, private router:Router) { }

  register(user: { name: string, password: string }): Observable<any> {
    return this.http.post(`${this.apiUrl}/register`, user);
  }

  checkEmailInUse(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/check-email`, { params: { email } });
  }

  recoverPassword(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/recover`, { params: { email } });
  }

  login(user: { email: string | undefined, password: string | undefined }): Observable<any> {
    return this.http.post(`${this.apiUrl}/login`, user,{headers:this.headers});
  }
  logOut(){
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl("/")
  }

}
