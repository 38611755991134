import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit {
  @Output() timeSelected = new EventEmitter<any>(); // Evento de salida para notificar cuando se selecciona una hora
  @Input() currentTime: any;
  hours: string[] = [];
  minutes: string[] = [];
  selectedHour: string | null ="00";
  selectedMinute: string | null ="00";
  isAllDayChecked: boolean = false;

  constructor() { }

    ngOnInit(): void {
      // Inicializar las opciones de horas (de 0 a 23)

     
     
      for (let i = 0; i < 24; i++) {
        this.hours.push(i.toString().padStart(2, '0'));
      }

      // Inicializar las opciones de minutos (de 0 a 59)
      for (let i = 0; i < 60; i++) {
        this.minutes.push(i.toString().padStart(2, '0'));
      }

   
        console.log(this.currentTime);
        
        if (this.currentTime == null) {
          this.isAllDayChecked=true;
        }else{
          const timeParts = this.currentTime.split(':'); // Separar la hora y los minutos
          const hour = timeParts[0];
          const minute = timeParts[1];

          this.selectedHour = hour
          this.selectedMinute = minute
        }
    
      
    }

    emitTime(): void {
      // Emitir el evento con la hora y los minutos seleccionados
      this.timeSelected.emit({ hour: this.selectedHour, minute: this.selectedMinute });
    }

    onAllDayCheckedChange(): void {
      if (this.isAllDayChecked) {
        this.selectedHour = null;
        this.selectedMinute = null;
      }else{
        this.selectedHour ="00";
        this.selectedMinute = "00";
      }
      this.emitTime()
    }
  }
