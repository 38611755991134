// app.module.ts

import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MapComponent } from './map/map.component';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleMapsModule } from '@angular/google-maps';
import { HistoryMapComponent } from './history-map/history-map.component';
import { TasksComponent } from './tasks/tasks.component';
import { TaskDialogComponent } from './tasks/task-dialog/task-dialog.component';
import { NbDatepickerModule, NbDialogModule, NbMenuModule, NbSidebarModule, NbSpinnerModule, NbThemeModule, NbTimepickerModule } from '@nebular/theme';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { RegisterComponent } from './auth/register/register.component';
import { PolicyComponent } from './policy/policy.component';
import { DriverDasboardComponent } from './driver-dasboard/driver-dasboard.component';
registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SidebarComponent,
    MapComponent,
    HistoryMapComponent,
    TasksComponent,
    TaskDialogComponent,
    SettingsComponent,
    RegisterComponent,
    PolicyComponent,
    DriverDasboardComponent
  ],
  imports: [
    BrowserModule,
    SharedModule, 
    AppRoutingModule,
    GoogleMapsModule,
    NbSpinnerModule,
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    NbThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
