<nb-layout>
  <nb-layout-header style="background-color: #f9fdfc;">
    <img src="assets/logo/thumbnail.JPG" width="30px" height="30px" alt="">
    <div class=" col-sm  text-center  mobile-buttons">
        <button nbButton appearance="ghost" (click)="switchPage(1)">Mapa</button>
        <button nbButton appearance="ghost" (click)="switchPage(2)">Historico</button>
        <button nbButton appearance="ghost" (click)="switchPage(3)">Tareas</button>
        <button nbButton appearance="ghost"  (click)="switchPage(4)">Configuración</button>
        <button nbButton (click)="logout()"  appearance="ghost" status="danger">
  <nb-icon icon="log-out-outline"></nb-icon>
 Salir
</button>

    </div>
  </nb-layout-header>

  <nb-layout-column class="p-0">

 <ng-container *ngIf="loading==false">
       <app-map *ngIf="page == 1" [devices]="devices"  [tasks]="tasks" [moveMap]="mapDirection" [users]="users" (page)="page=$event" (updateElements)="updateRequested()" (selectedDevice)="historyDeviceSelected($event)" [countDown]="countDown"></app-map>
      <app-history-map *ngIf="page == 2"  [devices]="devices" [selectedDevice]="selectedDevice" ></app-history-map>
 
 </ng-container>
 
    
    <app-tasks *ngIf="page == 3" (update)="updateTasks()" ></app-tasks>
    <app-settings *ngIf="page == 4" (devicesChanges)="devicesChanged($event)" ></app-settings>
  </nb-layout-column>
</nb-layout>
