export const environment = {
   googleApi: "AIzaSyA6tKq8HrfUMOdDp4hmohKo2Tpun_obhPk",

     firebaseConfig : {

        apiKey: "AIzaSyBblh_kXsQajYk1UsP5s1zJUIk0UtxzGsQ",

        authDomain: "nexus-a73fc.firebaseapp.com",

        projectId: "nexus-a73fc",

        storageBucket: "nexus-a73fc.appspot.com",

        messagingSenderId: "145116952336",

        appId: "1:145116952336:web:0aa914ee49b8577474d795",

        measurementId: "G-L1MGS344SX"

    },


    url: "https://gps.hiperstage.com/apinexus"
     // url: "http://192.168.1.139:3000"
   // url: "http://93.176.171.244:3000"
};
