<nb-layout>
  <nb-layout-column class="p-0">
    <div class="col-sm-12 text-center">
      <img src="../../assets/logo/logo1.JPG" height="200px" alt="" />
    </div>

    <div class="col-sm-12">
      <nb-card>
        <nb-card-body>
          <div class="col-sm">
            <p>Última actualización: 1 enero 2024</p>

            <p>
              1. Introducción TOROGPS ("nosotros", "nuestro") se compromete a
              proteger la privacidad de los usuarios ("usuario", "usted") de
              nuestra aplicación de rastreo de vehículos. Esta Política de
              Privacidad describe cómo recopilamos, usamos, divulgamos, y
              protegemos su información personal al usar nuestra aplicación
              TOROGPS, que requiere un registro simple utilizando su nombre y
              correo electrónico.
            </p>
            <p>
              2. Información que recopilamos Para utilizar TOROGPS, se le
              solicitará proporcionar información personal básica, incluyendo:
              Nombre Dirección de correo electrónico Esta información se utiliza
              para crear su cuenta, proporcionar el servicio de rastreo de
              vehículos, y para comunicarnos con usted.
            </p>

            <p>
              3. Uso de la información Utilizamos la información que recopilamos
              para: Ofrecer y mejorar nuestros servicios de rastreo de
              vehículos. Comunicarnos con usted, incluyendo enviar
              notificaciones sobre actualizaciones o cambios en nuestros
              servicios. Investigar y prevenir actividades fraudulentas o
              ilegales.
            </p>

            <p>
              4. Compartir y divulgar información No vendemos, alquilamos ni
              compartimos su información personal con terceros, excepto en las
              siguientes circunstancias: Con su consentimiento explícito. Para
              cumplir con la ley o en respuesta a un proceso legal (por ejemplo,
              una orden judicial). Para proteger los derechos, la propiedad o la
              seguridad de TOROGPS, nuestros usuarios o el público.
            </p>

            <p>
              5. Seguridad de la información Implementamos medidas de seguridad
              técnicas y organizativas para proteger su información personal
              contra el acceso no autorizado, la alteración, la divulgación o la
              destrucción. Sin embargo, ninguna transmisión de información a
              través de Internet o almacenamiento electrónico es completamente
              segura. Por lo tanto, no podemos garantizar su absoluta seguridad.
            </p>
            <p>
              6. Acceso y control de su información Usted tiene derecho a
              acceder, corregir o eliminar su información personal almacenada en
              nuestra aplicación. Puede hacerlo iniciando sesión en su cuenta o
              contactándonos directamente.
            </p>
            <p>
              7. Cambios en la política de privacidad Nos reservamos el derecho
              de modificar esta Política de Privacidad en cualquier momento.
              Cualquier cambio será efectivo inmediatamente después de la
              publicación de la política revisada en la aplicación. Le
              recomendamos revisar periódicamente esta política para estar
              informado sobre cómo protegemos su información.
            </p>
            <p>
              8. Contacto Si tiene preguntas o comentarios sobre esta Política
              de Privacidad, por favor contáctenos en: infohiperstage@gmail.com
              Agradecemos su confianza en TOROGPS para gestionar el rastreo de
              sus vehículos de manera segura y eficiente.
            </p>
        
                <div class="col text-center">
<button nbButton outline status="primary" appearance="outline" routerLink="/">Volver</button>
                </div>
          
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </nb-layout-column>
</nb-layout>
