import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, interval, startWith, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  url = environment.url+"/positions";
  private refreshInterval = 5000; 
  constructor(private http:HttpClient) { }


  // Método para realizar la solicitud cada 10 segundos
  getPositionWithInterval(list:number[]): Observable<any> {
    return interval(this.refreshInterval).pipe(
      startWith(0),
      switchMap(() => this.getDevicesPosition(list))
    );
  }

  getRouteByDevice(device:number, date:Date){
  //  console.log(this.url + "?id=" + device+"&date=2024-01-07");
    const formatDate = moment(date).format('yyyy-MM-DD');
    console.log(formatDate);
    
    
   return  this.http.get(this.url + "?id=" + device + "&date="+formatDate)
  }

  getDevicesPosition(list: number[]) {
    //  console.log(this.url + "?id=" + group+"&date=2024-01-07");

    return this.http.post(this.url + "/all/",  list )
  }

  getLastValidByDeviceId(id:number){
    return this.http.get(this.url +"/findLastValidByDeviceId/"+"?id="+id)
  }
}
