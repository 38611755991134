<div class="container-fluid">
  <div class="row m-2">
    <div class="col">
      <h2>Configuración</h2>
    </div>
  </div>

  <div class="row">
    <nb-tabset fullWidth fullHeigth>
      <nb-tab>
        <ng-template nbTabTitle>
          <nb-icon icon="car" pack="eva"></nb-icon>
        </ng-template>

        <div class="row">
          <div class="col-sm-12">
            <button
              class="m-1"
              nbButton
              status="primary"
              (click)="toggleNewDeviceForm()"
            >
              Nuevo Dispositivo
            </button>
          </div>
        </div>

  
            <div *ngIf="isNewDeviceFormVisible">
              <!-- Contenido del formulario para el nuevo usuario -->
              <nb-card>
                <nb-card-header>
                  <h4>Nuevo tracker</h4>
                </nb-card-header>
                <nb-card-body>
                  <input
                   class="m-1"
                    type="text"
                    [(ngModel)]="newDevice.name"
                    placeholder="Nombre"
                    nbInput
                  />
                  <input
                    class="m-1"
                    type="text"
                    [(ngModel)]="newDevice.uniqueid"
                    placeholder="Id etiqueta tracker"
                    nbInput
                  />
                  <!--        <select
                    class="d-block m-2"
                    [(ngModel)]="newDevice.userid"
                    nbSelect
                  >
                    <option [ngValue]="true">Administrador</option>
                    <option [ngValue]="false">Conductor</option>
                  </select> -->

                  <ng-container *ngIf="uniqueIdError">
                    <div class="alert alert-danger" role="alert">
                      El identificador ya existe
                    </div>
                  </ng-container>
                  
                </nb-card-body>
                <nb-card-footer>
                     <button
                class="m-1"
                nbButton
                status="success"
                (click)="addNewDevice()"

              >
                Agregar tracker
              </button>
                </nb-card-footer>
              </nb-card>

           
         
        </div>
        <div class="row">

       

        <div class="col m-2" *ngFor="let device of devices$ | async">
          <nb-card *ngIf="device.id">
            <nb-card-header>{{ device.name }}</nb-card-header>

            <nb-card-body>
              <label for=""><strong>Nombre:</strong></label>
              <div
                class="col-sm-12"
                *ngIf="editDeviceId === device.id; else notEditingName"
              >
                <input [(ngModel)]="device.name" nbInput />
              </div>
              <ng-template #notEditingName>
                <div class="col-sm-12">
                  {{ device.name }}
                </div></ng-template
              >
              <label for=""><strong>Numero indentificador:</strong></label>
              <div
                class="col-sm-12"
                *ngIf="editDeviceId === device.id; else notEditingEmail"
              >
                <input [(ngModel)]="device.uniqueid" nbInput />
              </div>
              <ng-template #notEditingEmail>
                <div class="col-sm-12">
                  {{ device.uniqueid }}
                </div>
              </ng-template>
            </nb-card-body>

            <nb-card-footer>
              <div *ngIf="editDeviceId === device.id; else notEditingButtons">
                <button
                  class="m-1"
                  nbButton
                  status="warning"
                  (click)="toggleEditDevice(device)"
                >
                  Cancelar
                </button>
                <button
                  class="m-1"
                  nbButton
                  status="success"
                  (click)="updateDevice(device)"
                >
                  Guardar
                </button>
              </div>
              <ng-template #notEditingButtons>
                <div class="row">
                  <div class="col">
                    <button
                      class="m-1"
                      nbButton
                      status="danger"
                      (click)="deleteDevice(device)"
                    >
                      Borrar
                    </button>
                    <button
                      class="m-1"
                      nbButton
                      status="primary"
                      (click)="toggleEditDevice(device)"
                    >
                      Editar
                    </button>
                  </div>
                </div>
              </ng-template>
            </nb-card-footer>
          </nb-card>
        </div>
         </div>
      </nb-tab>

      <nb-tab>
        <ng-template nbTabTitle>
          <nb-icon icon="people" pack="eva"></nb-icon>
        </ng-template>
        <div class="row">
          <div class="col-12">
            <button
              class="m-1"
              nbButton
              status="primary"
              (click)="toggleNewUserForm()"
            >
              Nuevo Usuario
            </button>
          </div>
        </div>

      
          <div class="row">
            <div class="col-sm m-2">
              <div *ngIf="isNewUserFormVisible">
                <!-- Contenido del formulario para el nuevo usuario -->
                <nb-card  class="d-flex">
                  <nb-card-header>
                    <h4>Nuevo Usuario</h4>
                  </nb-card-header>
                  <nb-card-body>
                    <input
                    class="m-1"
                      type="text"
                      [(ngModel)]="newUser.name"
                      placeholder="Nombre"
                      nbInput
                    />
                    <input
                    class="m-1"
                      type="email"
                      [(ngModel)]="newUser.email"
                      placeholder="Email"
                      nbInput
                    />
                    <input
                    class="m-1"
                      type="password"
                      [(ngModel)]="newUser.password"
                      placeholder="contraseña"
                      nbInput
                    />
                    <select
                      class="m-1"
                      [(ngModel)]="newUser.nexusadmin"
                      nbSelect
                    >
                      <option [ngValue]="true">Administrador</option>
                      <option [ngValue]="false">Conductor</option>
                    </select>
                    <span class="d-block m-2">
                      <small
                        >El administrador tiene acceso al mapa y gestiona las
                        tareas</small
                      ></span
                    >
                    <span class="d-block m-2">
                      <small
                        >El conductor tiene acceso a sus tareas y puede
                        interacturar con ellas</small
                      ></span
                    >
                    <ng-container *ngIf="emailError">
                         <span class="d-block m-2">
                      <small class="text-danger"
                        >El correo ya esta registrado</small
                      ></span
                    >
                    </ng-container>
                    
                  </nb-card-body>
                    <nb-card-footer>
     <button
                  class="m-1"
                  nbButton
                  status="success"
                  (click)="addNewUser()"
                  [disabled]="!newUser.name || !newUser.email || !newUser.password"
                >
                  Agregar Usuario
                </button>
                    </nb-card-footer>
                </nb-card>

           
              </div>
            </div>
          </div>

      <div class="row">
            <div
            class="col m-2"
            *ngFor="let user of users$ | async; let i = index"
          >
            <nb-card >
              <nb-card-header>
                <label for=""><strong>Rol:</strong></label>
                <div class="col-sm-12" *ngIf="user.owner; else adminDriver">
                  Propieatario cuenta
                </div>
                <ng-template #adminDriver>
                  <div *ngIf="editUserId === user.id; else notEditingName">
                    <select [(ngModel)]="user.nexusadmin" nbSelect>
                      <option [ngValue]="true">Administrador</option>
                      <option [ngValue]="false">Conductor</option>
                    </select>
                  </div>
                  <ng-template #notEditingName>
                    <div>
                      {{ user.nexusadmin ? "Administrador" : "Conductor" }}
                    </div>
                  </ng-template>
                </ng-template>
              </nb-card-header>
              <nb-card-body>
                <label for=""><strong>Nombre:</strong></label>
                <div
                  class="col-sm-12"
                  *ngIf="editUserId === user.id; else notEditingName"
                >
                  <input [(ngModel)]="user.name" nbInput />
                </div>
                <ng-template #notEditingName>
                  <div class="col-sm-12">
                    {{ user.name }}
                  </div></ng-template
                >
                <label for=""><strong>Email:</strong></label>
                <div
                  class="col-sm-12"
                  *ngIf="editUserId === user.id; else notEditingEmail"
                >
                  <input [(ngModel)]="user.email" nbInput  disabled/>
                </div>
                <ng-template #notEditingEmail>
                  <div class="col-sm-12">
                    {{ user.email }}
                  </div>
                </ng-template>
              </nb-card-body>
              <nb-card-footer>
                <div *ngIf="editUserId === user.id; else notEditingButtons">
          
                  <button
                    class="m-1"
                    nbButton
                    status="warning"
                    (click)="toggleEditUser(user)"
                  >
                    Cancelar
                  </button>
                  <button
                    class="m-1"
                    nbButton
                    status="success"
                    (click)="updateUser(user)"
                  >
                    Guardar
                  </button>
                       <ng-container *ngIf="emailError">
                         <span class="d-block m-2">
                      <small class="text-danger"
                        >El correo ya esta registrado</small
                      ></span
                    >
                    </ng-container>
                </div>
                <ng-template #notEditingButtons>
                  <div class="row">
                    <div class="col-sm">
                      <button
                        class="m-1"
                        nbButton
                        status="danger"
                        (click)="deleteUser(user)"
                        [disabled]="user.owner == true"
                      >
                        Borrar
                      </button>
                      <button
                        class="m-1"
                        class="m-1"
                        nbButton
                        status="primary"
                        (click)="toggleEditUser(user)"
                      >
                        Editar
                      </button>
                    </div>
                  </div>
                </ng-template>
              </nb-card-footer>
            </nb-card>
          </div>
      </div>
     
      </nb-tab>
            <nb-tab>
        <ng-template nbTabTitle>
          <nb-icon icon="shopping-cart" pack="eva"></nb-icon>
        </ng-template>
    <nb-card >
              <nb-card-header>
                  M1
              </nb-card-header>
              <nb-card-body>
                <div class="row">
        <div class="col-sm-6">
     <img src="/assets/store/m1.jpg"  class="img-fluid">
                </div>
            <div class="col-sm-6">
      
                <nb-list>
                <nb-list-item>
                <p>  Permite <strong> Instalación oculta</strong></p>
                </nb-list-item>
                   <nb-list-item>
      <p>            Se puede conectar a cualquier <strong> toma auxiliar</strong> del vehiculo</p>
                </nb-list-item>
                   <nb-list-item>
                <p>  Posiciona el vehiculo en tiempo real <strong> cada 15 segundos</strong></p>
                </nb-list-item>
                   <nb-list-item>
             <p>     Detecta <strong>movimiento</strong></p>
                </nb-list-item>
                    <nb-list-item>
         <p>        Permite <strong> asignación de tareas y conductor</strong></p>
                </nb-list-item>
                           <nb-list-item>
              <p>   Modelo mas <strong> economico</strong></p>
                </nb-list-item>
                </nb-list>
      
            </div>
                </div>
        


      
         
              </nb-card-body>
           <nb-card-footer class="d-flex justify-content-center align-items-center">
    <a nbButton status="primary" class="text-center" href="https://www.amazon.es/dp/B0CXNDMS9N?ref=myi_il_dp" target="_blank" >Ir a tienda</a>
</nb-card-footer>

            </nb-card>
         <!--        <nb-card >
              <nb-card-header>
                  M2  ODB
              </nb-card-header>
              <nb-card-body>
                <img src="/assets/store/m1.jpg"  class="img-fluid">
              </nb-card-body>
        
       <nb-card-footer class="d-flex justify-content-center align-items-center">
    <button nbButton status="primary" class="text-center">Ir a tienda</button>
</nb-card-footer>

            </nb-card> -->
      </nb-tab> 
    </nb-tabset>
  </div>
</div>
