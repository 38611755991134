import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../interfaces/user.interface';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.url + "/user";

  constructor(private http: HttpClient) { }

  private getHeaders() {
    const authToken = localStorage.getItem("token");
    return {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    };
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiUrl}/users`, this.getHeaders());
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/${userId}`, this.getHeaders());
  }

  create(user: User): Observable<User> {
    return this.http.post<User>(this.apiUrl, user, this.getHeaders());
  }

  update(userId: number, user: User): Observable<User> {
    return this.http.put<User>(`${this.apiUrl}/${userId}`, user, this.getHeaders());
  }

  delete(userId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${userId}`, this.getHeaders());
  }

  getUser(): Observable<any> {
    return this.http.get(`${this.apiUrl}/me`, this.getHeaders());
  }
}
