 <header class="header">
    <div class="container">
      <div class="row  m-2">
             <div class="col-2 float-start">
    <img src="assets/logo/thumbnail.JPG" width="50px" height="50px" alt="">
</div>
<div class="col d-block text-end">
    <input type="date" name="date" id="date" [(ngModel)]="selectedDate" (ngModelChange)="onDateChange()">
    <button type="button" class="btn btn-primary m-2" *ngIf="user$ | async as user">{{ user.name }}</button>
    <button type="button" class="btn btn-secondary m-2">Salir</button>
</div>
<h2>Tareas dia {{selectedDate}}</h2>


      </div>
    </div>
  </header>
  
  <!-- Contenido principal -->
  <main class="container " *ngFor="let task of tasks$  | async   ">
    <div class="row mt-5 pt-4">
      <!-- Card 1 -->
      <div class="col-12">
        <div class="card">
            <div class="card-header">
                {{task?.hour}}
            </div>
          <div class="card-body">
            <h5 class="card-title"> {{task.name}}</h5>
            <p class="card-text">{{task.address}}</p>
            <p>{{task.description}}</p>
            
            <button type="button" class="btn btn-primary float-start" (click)="loadRoute(task)">Cargar en maps</button>
            
            <button type="button" class="btn btn-success float-end">Completar</button>
          </div>
        </div>
      </div>

    </div>
  </main>