import { Component, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { DeviceService } from '../services/device.service';
import { Device } from '../interfaces/device.interface';
import { TaskService } from '../services/task.service';
import { Observable, Subject, forkJoin, interval, map, mergeMap, startWith, switchMap, take, takeUntil, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

import { GoogleMapsLoaderService } from '../services/google-maps-loader.service';
import { Loader } from "@googlemaps/js-api-loader"
import { environment } from 'src/environments/environment.development';
import { PositionService } from '../services/position.service';
import { UserService } from '../services/user.service';
import { User } from '../interfaces/user.interface';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
   destroy$: Subject<void> = new Subject();
  @Input()  page:number=1;
  devices: any | undefined=[];
  tasks: any | undefined = [];
  currentDate= new Date()
  mapDirection: any | undefined={};
  newPosition = new EventEmitter<any>();
  @Input() selectedDevice: Device | undefined;
  @Input() updateElements: any | undefined; 
  user= localStorage.getItem("user");
  refreshInterval = 10000;
  countDown=0;
  loading=true;
  users!:User[]
  
  constructor( private deviceService: DeviceService,
      private authService:AuthService, 
    private taskService: TaskService,
    private positionService:PositionService,
    private userService:UserService
  ) {

    const loader = new Loader({
      apiKey: environment.googleApi,
    });
    loader.load().then(async () => {
      this.loading=false

      });




  }



  ngOnInit(): void {
    this.getDevicesWithInterval().subscribe();
    this.getTasks();
    this.getUsers()

  
    
  }


  historyDeviceSelected(valor:any){
console.log("triggered",valor);
this.selectedDevice=valor
  }


getDevicesWithInterval(): Observable < any > {

  return interval(this.refreshInterval).pipe(
    startWith(0),
    switchMap(async () =>{
      if (this.page==1) {
        this.getDevices()
      }

    }))

}


  devicesChanged(devices: Device[]){
    this.devices = devices
  
  }

  getDevices() {
    this.deviceService.getDevices().pipe(
      mergeMap((devices: any[]) => {
        const observables = devices.map((device: any) => {
          if (!device.position?.valid || device.position.latitude === 0) {
            return this.positionService.getLastValidByDeviceId(device.id).pipe(
              take(1),
              map((res2: any) => {
                if (res2) {
                  console.log(device);
                  if (device.position) {
                    device.position.latitude = res2.latitude
                    device.position.longitude = res2.longitude  
                  }else{
                    device.position={
                      latitude : res2.latitude,
                      longitude :res2.longitude 
                    }
                  }
              
                  
                }
                
                return device;
              })
            );
          } else {
            return [device]; 
          }
        });
        return forkJoin(observables);
      })
    ).subscribe((updatedDevices: any[]) => {
      this.devices=updatedDevices
    });
  }

  updateTasks(){
    this.getTasks()
  }
 

  getTasks() {
    this.taskService.getMany(this.currentDate).pipe(
      takeUntil(this.destroy$) // Se detiene la suscripción cuando unsubscribe$ emite un valor.
    ).subscribe(res => {

      this.tasks = [...res];
    });
  }

  moveMap(position:any){
    this.mapDirection = { 
      lat:position.latitude,
    lng:position.longitude };
  }

  updateRequested(){
    this.getDevices();
    this.getTasks()
  }



  switchPage(newPage: number) {
    this.page = newPage;
    this.destroy$.next();
    this.destroy$.complete();
   // this.cdr.detectChanges();
  }

  logout(){

    this.authService.logOut();
    this.destroy$.next();
    this.destroy$.complete();
    
  }


  getUsers() {
    this.userService.getUsers().pipe(
      take(1)
    ).subscribe(users => {
      this.users = users;
    });
  }




   
}
