import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { PositionService } from '../services/position.service';
import { DeviceService } from '../services/device.service';
import { Device } from '../interfaces/device.interface';
import { Position } from '../interfaces/position.interface';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-history-map',
  templateUrl: './history-map.component.html',
  styleUrls: ['./history-map.component.scss']
})
export class HistoryMapComponent {
  @Input() devices: Device[] = [];
  @Input() selectedDevice: Device | undefined;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;
  ngModelDate = new Date();
  positions: Position[] = [];
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  center: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0
  };
  selectedMarker: any;
  zoom = 12;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    maxZoom: 18,
    minZoom: 6
  };
  markers: any[] = [];
  infoContent = '';
  tiempoRestante: number = 5;
  routePath: google.maps.LatLngLiteral[] = [];
  isMinimized: boolean = false;
  finishLoop: boolean = true;
  intervalId:any = null;
loading = true;
  constructor(private positionService: PositionService, private deviceService: DeviceService, private cdr: ChangeDetectorRef) {
  
 /*    if (Capacitor.getPlatform() == 'web') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.center = pos;
            this.loading = false;
          }
        );
      }
    } else {
      this.requestLocationPermission();
      this.getCurrentLocation().then((res: any) => {
        const pos = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
        this.center = pos
        this.loading = false;
      })
    } */


  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.devices.length > 0 && !this.selectedDevice) {
      this.selectedDevice = this.devices[0];
    
    }
    if (changes['selectedDevice'].currentValue) {
      const selectedId = changes['selectedDevice'].currentValue.info.id; 
      this.selectedDevice = this.devices.find(device => device.id === selectedId);
    }

    const pos = {
      lat: this.selectedDevice?.position?.latitude || 0,
      lng: this.selectedDevice?.position?.longitude || 0,
    };
    this.center = pos;
    this.loading=false;
    
  }

  async getCurrentLocation() {
    const coordinates = await Geolocation.getCurrentPosition();
    return coordinates
  }
  async requestLocationPermission() {
    const permission = await Geolocation.requestPermissions();
    console.log('Permission:', permission);
  }

  searchRoute() {
    this.markers = [];
    this.routePath = [];
    this.positions = [];
    if (this.selectedDevice?.id) {

      this.positionService.getRouteByDevice(this.selectedDevice.id, this.ngModelDate).subscribe((res: any) => {
        this.positions = [...res];
        var list:any = [];
        if (this.positions.length > 0) {
      
          if (this.positions[0].latitude && this.positions[0].longitude) {
            this.addMarker(this.positions[0].latitude, this.positions[0].longitude, this.positions[0])
          }
          const count = this.positions.length-1;
            this.addMarker(this.positions[count].latitude, this.positions[count].longitude, this.positions[count])
        }
        res.forEach((p: Position) => {
          if (p.latitude && p.longitude) {
          //  this.addMarker(p.latitude, p.longitude, p)
          const coor = {lat:p.latitude,lng:p.longitude}
          list.push(coor);
          }
          
          //this.routePath = this.markers.map(marker => marker.position);
        });
        this,this.routePath=list;
        this.center = this.routePath[0];
      });
    }
  }
 
  stopRoute() {
    this.finishLoop = true;
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null; // Resetea el identificador después de detener el intervalo
    }
  }

  PlayRoute(i?:number) {
    this.finishLoop = false;
    const total = this.routePath.length;
    let pos = 0;
    if (i) {
      pos = i;
    }
    // Guarda el identificador del intervalo
    
     this.intervalId = setInterval(() => {
      if (pos < total) {
        this.center = this.routePath[pos];
        pos++;
      } else {
        clearInterval(this.intervalId);
      }
    }, 500);
  }

  addMarker(lat: number | undefined, lng: number | undefined, info: any) {
    const length = this.markers.length + 1;
    info.pos=length
    let marker = {
      position: { lat, lng },
      labelClass: "labels",
      label: {
        color: 'black',
        text:info.pos.toString(),
        fontSize: "10px"

      },
      title: info.name,
      info: info,
      options: {
        // Usar un SVG como icono
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 10, // Tamaño del punto
          fillColor: '#FFFFFF', // Color de relleno: Azul
          fillOpacity: 100, // Opacidad del relleno
          strokeWeight: 2, // Grosor del borde
          strokeColor: '#0000FF', // Color del borde: Blanco
          
        }
      }
    }
    this.markers.push(marker);
  }

  openInfo(marker: MapMarker, selected: any) {
    this.selectedMarker = selected;
    console.log(selected);
    
    this.info.open(marker);
  }

  closeInfo() {
    this.info.close()
  }

  goToMarker(marker:any) {
    console.log(marker);
    const pos = {
      lat: marker.latitude,
      lng: marker.longitude,
    };

    this.addMarker(marker.latitude, marker.longitude, marker);
    
    this.center=pos
  }

  toggleMinimize(): void {
    this.isMinimized = !this.isMinimized;
  }


}
