<div class="container">
  <div class="row m-2">
    <div class="col">
      <h2>Tareas dia : {{ ngModelDate | date : "dd-MM-yy" }}</h2>
    </div>
  </div>

  <div class="row m-2">
    <div class="col-sm p-2">
      <input
        nbInput
        placeholder="Form Picker"
        [nbDatepicker]="ngmodel"
        [(ngModel)]="ngModelDate"
        (ngModelChange)="onDateChange($event)"
      />
      <nb-datepicker #ngmodel></nb-datepicker>
    </div>
    <div class="col-sm p-2">
      <button
        nbButton
        status="primary"
        size="small"
        (click)="toggleNewTaskForm()"
      >
        Nueva Tarea
      </button>
    </div>
  </div>
  <div *ngIf="isNewTaskFormVisible">
    <nb-card>
      <nb-card-header>
        <h4>Nueva tarea</h4>
      </nb-card-header>
      <nb-card-body>
        <form #taskForm="ngForm">
          <div class="flex-row">
            <div class="col-sm-12">
              <label for="name">Titulo:</label>
              <input
                nbInput
                id="name"
                name="name"
                type="text"
                class="form-control"
                required
                [(ngModel)]="task.name"
              />
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label for="date">Fecha:</label>
                <input
                  type="date"
                  class="form-control"
                  id="date"
                  name="date"
                  placeholder="Fecha"
                  required
                  disabled
                  [ngModel]="task.date | date : 'yyyy-MM-dd'"
                  (ngModelChange)="task.date = $event"
                />
              </div>

              <div class="col-sm-12">
                <app-timepicker
                  (timeSelected)="onTimeSelected($event)"
                  [currentTime]="task.hour"
                ></app-timepicker>
              </div>
            </div>
            <div class="col-sm-12">
              <label for="description">Descripción:</label>
              <textarea
                nbInput
                id="description"
                name="description"
                class="form-control"
                [(ngModel)]="task.description"
              ></textarea>
            </div>
            <div class="col-sm-12">
              <label for="address">Dirección:</label>
              <!--  <input nbInput id="address" name="address" type="text" class="form-control" required [(ngModel)]="task.address"> -->
              <app-places-autocomplete
                (placeSelected)="onAddressSelected($event)"
                [currentAddress]="task.address"
              ></app-places-autocomplete>
            </div>
            <div class="col-sm-12">
              <label for="assigned_to">Conductor asignado:</label>
              <select
                class="form-control"
                [(ngModel)]="task.assigned_to"
                name="assigned_to"
              >
                <option [ngValue]="null">No Asignado</option>
                <option *ngFor="let user of users" [ngValue]="user.id">
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </nb-card-body>
      <nb-card-footer>
        <button
          class="m-1"
          nbButton
          status="success"
          (click)="createTask()"
          [disabled]="taskForm.valid == false"
        >
          Agregar Tarea
        </button>
      </nb-card-footer>
    </nb-card>
  </div>

  <ng-container *ngIf="tasks && tasks.length > 0; else noTasks">
    <div *ngFor="let task of tasks">
      <nb-card *ngIf="editTaskId === task.id; else notEditingName">
        <form #editTaskForm="ngForm">
          <nb-card-header>
            <div class="row m-2">
              <div class="col-sm-12">
                <label for="Title">Titulo:</label>
                <input
                  id="Title"
                  name="Title"
                  required
                  class="form-control w-100"
                  [(ngModel)]="task.name"
                />
              </div>

              <!-- aqui -->
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="date">Fecha:</label>
                  <input
                    type="date"
                    class="form-control"
                    id="date"
                    name="date"
                    placeholder="Fecha"
                    required
                    [ngModel]="task.date | date : 'yyyy-MM-dd'"
                    (ngModelChange)="task.date = $event"
                  />
                </div>

                <div class="col-sm-12">
                  <app-timepicker
                    (timeSelected)="onExistingTaskTimeSelected($event, task)"
                    [currentTime]="task.hour"
                  ></app-timepicker>
                </div>
              </div>
              <div class="col-sm-12">
                <label for="address">Dirección:</label>
                <app-places-autocomplete
                  (placeSelected)="onExistingTaskAddressSelected($event, task)"
                  [currentAddress]="task.address"
                ></app-places-autocomplete>
              </div>
              <div class="col-sm-12">
                <label for="assigned_to">Conductor asignado:</label>
                <select
             
                  class="form-control"
                  [(ngModel)]="task.assigned_to"
                  name="assigned_to"
                >
                  <option [ngValue]="null">No Asignado</option>
                  <option *ngFor="let user of users" [ngValue]="user.id">
                    {{ user.name }}
                  </option>
                </select>
              </div>
            </div>
          </nb-card-header>

          <nb-card-body>
            <div class="row m-2">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="description">Descripción:</label>
                  <textarea
                    id="description"
                    name="description"
                    class="form-control"
                    [(ngModel)]="task.description"
                  ></textarea>
                </div>
              </div>
            </div>
          </nb-card-body>
          <nb-card-footer>
            <div class="row d-flex">
              <div class="col-sm d-flex justify-content-center">
                <button
                  nbButton
                  class="m-1"
                  status="success"
                  [disabled]="buttonDisabled || editTaskForm.valid == false"
                  size="small"
                  (click)="this.toggleEditTask(task)"
                >
                  Volver
                </button>
              </div>
              <div class="col-sm d-flex justify-content-center">
                <button
                  nbButton
                  class="m-1"
                  [disabled]="buttonDisabled || editTaskForm.valid == false"
                  status="primary"
                  size="small"
                  (click)="editTask(task)"
                >
                  Guardar
                </button>
              </div>
            </div>
          </nb-card-footer>
        </form>
      </nb-card>

      <ng-template #notEditingName>
        <nb-card>
          <nb-card-header>
            <div class="col-sm-12 d-flex justify-content-between">
              <div>
                Titulo: <strong> {{ task.name }}</strong>
              </div>
              <ng-container *ngIf="task.done">
                <nb-icon
                  style="background-color: rgb(32, 237, 63); color: white"
                  icon="checkmark-outline"
                ></nb-icon>
              </ng-container>
            </div>
            <div class="row">
              <div *ngIf="task.hour; else allDay" class="col-6">
                Hora:
                <strong>
                  {{ task.hour }}
                </strong>
              </div>
              <ng-template #allDay>
                <div class="col-6">
                  Hora: <strong>Todo el dia</strong>
                </div></ng-template
              >

              <div class="col-sm-12">
                <strong>{{ task.address }}</strong>
              </div>
              <div class="col-sm-12" *ngIf="task.assigned_to; else noAssigned">
                <strong>
                  {{ this.getUserNameById(task.assigned_to) }}
                </strong>
              </div>
              <ng-template #noAssigned>
                <div class="col-sm-12">
                  <strong> Sin asignar </strong>
                </div>
              </ng-template>
            </div>
          </nb-card-header>
          <nb-card-body>
            <strong>{{ task.description }}</strong>
          </nb-card-body>

          <nb-card-footer>
            <div class="row d-flex">
              <div class="col-sm d-flex justify-content-center">
                <button
                  nbButton
                  class="m-1"
                  [disabled]="buttonDisabled"
                  status="danger"
                  size="small"
                  (click)="deleteTask(task.id)"
                >
                  Borrar
                </button>
              </div>
              <div class="col-sm d-flex justify-content-center">
                <button
                  nbButton
                  class="m-1"
                  [disabled]="buttonDisabled"
                  status="primary"
                  size="small"
                  (click)="toggleEditTask(task)"
                >
                  Editar
                </button>
              </div>
              <div class="col-sm d-flex justify-content-center">
                <ng-container *ngIf="!task.done; else doneBtn">
                  <button
                    nbButton
                    class="m-1"
                    status="success"
                    [disabled]="buttonDisabled"
                    size="small"
                    (click)="toggleTaskStatus(task)"
                  >
                    Terminar
                  </button>
                </ng-container>
                <ng-template #doneBtn>
                  <button
                    nbButton
                    class="m-1"
                    status="alert"
                    [disabled]="buttonDisabled"
                    size="small"
                    (click)="toggleTaskStatus(task)"
                  >
                    Marcar como pendiente
                  </button>
                </ng-template>
              </div>
            </div>
          </nb-card-footer>
        </nb-card>
      </ng-template>
    </div>
  </ng-container>

  <ng-template #noTasks>
    <div class="row">
      <div class="col">
        <div class="alert alert-primary text-center" role="alert">
          No hay tareas para hoy
        </div>
      </div>
    </div>
  </ng-template>
</div>
