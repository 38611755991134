import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker, MarkerClustererOptions, } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Device } from '../interfaces/device.interface';
import { TaskService } from '../services/task.service';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import { Observable, catchError, map, of, take, takeUntil } from 'rxjs';
import { PositionService } from '../services/position.service';
import { User } from '../interfaces/user.interface';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild('info') info!: MapInfoWindow;
  @ViewChild('info2') info2!: MapInfoWindow;
  /*   @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;
    @ViewChild(MapInfoWindow, { static: false }) info2!: MapInfoWindow; */
  selectedMarker: any;
  date = new Date();
  @Input("devices") devices: any;
  @Input("users") users: User[] = [];
  @Input("tasks") tasks: any;
  @Input("countDown") countDown: any;
  @Output() selectedDevice = new EventEmitter<Device>();
  @Output() page = new EventEmitter<number>();
  @Output() updateElements = new EventEmitter<string>();
  @Input("moveMap") moveMap: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0
  }
  center: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0
  };
  zoom = 12;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    maxZoom: 18,
    minZoom: 6,
    gestureHandling: 'greedy', // Deshabilitar Street View
    disableDefaultUI: true
  };
  markers: any[] = [];
  taskMarkers: any[] = [];
  infoContent = '';
  routePaths: any[] = [];
  loading = true;

  colors: any[] = [
    '#FF5733', // Rojo
    '#33FF57', // Verde
    '#3357FF', // Azul
    '#F833FF', // Magenta
    '#FF8333', // Naranja
    '#33FFF8', // Cian
    '#F8FF33', // Amarillo
    '#8333FF', // Púrpura
    '#FF3380', // Rosa
    '#3380FF', // Celeste
  ];
  isMinimized: boolean = false;
  constructor(private taskService: TaskService, private router: Router, private cdr: ChangeDetectorRef, private positionService: PositionService) {




    if (Capacitor.getPlatform() == 'web') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.center = pos;
            this.loading = false;
          }
        );
      }
    } else {
      this.requestLocationPermission().then(res => {
        console.log(res)
        this.getCurrentLocation().then((res: any) => {
          const pos = {
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          };
          this.center = pos
          this.loading = false;
        })
      });
      /*       this.getCurrentLocation().then((res: any) => {
              const pos = {
                lat: res.coords.latitude,
                lng: res.coords.longitude,
              };
              this.center = pos
              this.loading = false;
            }) */
    }



  }



  ngOnChanges(changes: SimpleChanges): void {
console.log(changes);


    if (changes['tasks']) {
      this.taskMarkers = []
      if (this.tasks.length > 0) {
        console.log(this.tasks);
        
        this.tasks.forEach((task: any) => {
          if (task.latitude && task.longitude) {
            let editedTask = task;
            editedTask.position = { lat: task.latitude, lng: task.longitude }
            this.addTaskMarker(task.latitude, task.longitude, editedTask)
          }
        });
      }
    }
    if (changes['devices']) {


      if (this.devices.length > 0) {
        this.devices.forEach((device: any, index: number) => {

          const position = device?.position;
          console.log(position);
          
          const current = this.markers.find((marker: any) => marker.info.id === device.id);

          if (position) {
            if (current) {
                current.position = { lat: device.position.latitude, lng: device.position.longitude };
                current.info = device;
            }  else {
            
              
              this.addMarker(device.position.latitude, device.position.longitude, device);
            } 
            if (!this.routePaths[index]) {
              this.routePaths[index] = [];
            }
         
          
            if (position.valid && position.lng !== 0) {
              const newPoint = { lat: device.position.latitude, lng: device.position.longitude };
              const lastPoint = this.routePaths[index].length > 0 ? this.routePaths[index][this.routePaths[index].length - 1] : null;
              if (!lastPoint || lastPoint.lat !== newPoint.lat || lastPoint.lng !== newPoint.lng) {
                this.routePaths[index] = [...this.routePaths[index], newPoint];
              }
            }
       
          } 


        });
      }
    }
 
    if (changes["moveMap"]) {

      if (changes["moveMap"].isFirstChange() == false) {
        this.center =
        {
          lat: this.moveMap.lat,
          lng: this.moveMap.lng
        };
      }

    }

  }



  async getCurrentLocation() {
    const coordinates = await Geolocation.getCurrentPosition();
    return coordinates
  }
  async requestLocationPermission() {
    const permission = await Geolocation.requestPermissions();
    console.log('Permission:', permission);
  }

  toggleMinimize(): void {
    this.isMinimized = !this.isMinimized;
  }

  completeTask(marker: any) {
    console.log(marker);
    let task: any = { ...marker.info };
    task.position = undefined;

    if (task) {
      task.done = !task.done;
      this.taskService.update(task.id, task)
        .pipe(take(1))
        .subscribe({
          next: (updatedTask) => {

            this.updateElements.emit("update")


          },
          error: (error) => {
            console.error('Error updating task', error);
            // Manejo de errores, por ejemplo, mostrar un mensaje de error al usuario.
          }
        });
    } else {
      console.log('Task not found for marker', marker);
      // Manejo del caso en que la tarea no se encuentra, por ejemplo, mostrar un mensaje al usuario.
    }
  }




  click(event: google.maps.MapMouseEvent) {
    console.log(event);
  }
  logCenter() {
    console.log(JSON.stringify(this.map.getCenter()))
  }
  addMarker(lat: number, lng: number, info: any) {
    let marker = {
      position: { lat, lng },
      labelClass: "labels",
      label: {
        color: 'black',
        text: info.name,
        fontSize: "24px",


      },
      title: info.name,
      info: info,
      options: {
        icon: {
          url: '/assets/markers/bus.png',
          scaledSize: new google.maps.Size(32, 32),
          labelOrigin: new google.maps.Point(16, 48)
        }
      }
    }
    this.markers.push(marker);
  }

  addTaskMarker(lat: number, lng: number, info: any) {
    console.log(info);


    const url = `/assets/taskmarkers/final.png`;
    let marker = {
      position: { lat, lng },
      labelClass: "labels",
      label: {
        color: 'black',
        text: info.name,
        fontSize: "24px"
      },
      title: info.name || "sin titulo",
      info: info,
      options: {
        icon: {
          url: url,
          scaledSize: new google.maps.Size(32, 32),
          labelOrigin: new google.maps.Point(16, -22)
        }
      }
    }
    this.taskMarkers.push(marker);
  }
  openInfo(marker: MapMarker, selected: any) {
    console.log("device");
    this.selectedMarker = selected

    this.info.open(marker);


  }
  openTaskInfo(marker: MapMarker, selected: any) {
    console.log("task");
    this.selectedMarker = selected;
    console.log(this.info2);
    this.info2.open(marker);
  }

  closeInfo() {
    this.info2.close()
    this.info.close()
  }

  showHistory(device: Device) {
    this.selectedDevice.emit(device)
    this.page.emit(2)

  }

  moveMapMethod(device: any) {
    console.log(device);

    this.center = {
      lat: device.position.latitude,
      lng: device.position.longitude
    };


  }

  goToTasks() {
    this.page.emit(3)
  }

  goToDevices() {
    this.page.emit(4)
  }

  getUserNameById(id: number) {

      const user = this.users?.find(user => user.id === id)
      if (user) {
        return user.name
      }
      
    

    return null
  }
  
}


