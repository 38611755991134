import { Component, ViewChild, ElementRef, ViewChildren, Output, EventEmitter, Input } from '@angular/core';

declare const google: any;

@Component({
  selector: 'app-places-autocomplete',
  templateUrl: './places-autocomplete.component.html',
  styleUrls: ['./places-autocomplete.component.scss']
})
export class PlacesAutocompleteComponent {
  @ViewChild('searchBox', { static: false }) searchBox!: ElementRef;
  @Output() placeSelected = new EventEmitter<any>();
  @Input() currentAddress: any;


    ngAfterViewInit(): void {
      let input = this.searchBox.nativeElement;
      if (this.currentAddress) {
        input.value=this.currentAddress;
      }

      const options = {
        componentRestrictions: { country: "ES" },
        fields: ["formatted_address","geometry"],
        strictBounds: false,
        types: ["address"],
      };

      input.addEventListener('input', () => {

        if (input.value.length >= 5) {
          const autocomplete = new google.maps.places.Autocomplete(input, options);
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            const placeObject = {
              name: place.formatted_address,
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng()
            };
            this.placeSelected.emit(placeObject);
          });
        }
      });
   

  
 

      }
}
