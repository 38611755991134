import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class loggedGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(): boolean {
    if (localStorage.getItem('token')) {
   
      this.router.navigate(['/main']);
      return false; // Retorna false para cancelar la navegación actual
    }
    return true; // Permite la navegación si no hay token
  }
}
