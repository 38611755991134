<nb-layout>
  <nb-layout-column>
    <nb-card>
      <nb-card-header>
        <div class="row">
  <div class="col-12 text-center">
    <img
      src="/assets/logo/logo1.JPG"
      class="img-fluid"
      alt="image desc"
    />
  </div>
  
  <div class="col-12">
 Registro
  </div>
</div>
        
        
    
    </nb-card-header>
 <nb-card-body>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
             <div class="form-group">
            <input nbInput type="email" placeholder="Email" formControlName="email" fullWidth>
            <div class="red-text" *ngIf="registerForm.get('email')?.touched && registerForm.get('email')?.hasError('required')">El email es obligatorio.</div>
            <div class="red-text" *ngIf="registerForm.get('email')?.touched && registerForm.get('email')?.hasError('email')">El email no es válido.</div>
          </div>
          <div class="form-group">
            <input nbInput placeholder="Nombre" formControlName="name" fullWidth>
            <div class="red-text" *ngIf="registerForm.get('name')?.touched && registerForm.get('name')?.hasError('required')">El nombre es obligatorio.</div>
          </div>
          <div class="form-group">
            <input nbInput type="password" placeholder="Contraseña" formControlName="password" fullWidth>
            <div class="red-text" *ngIf="registerForm.get('password')?.touched && registerForm.get('password')?.hasError('required')">La contraseña es obligatoria.</div>
            <div class="red-text" *ngIf="registerForm.get('password')?.touched && registerForm.get('password')?.hasError('minlength')">La contraseña debe tener al menos 6 caracteres.</div>
          </div>
          <div class="form-group">
            <input nbInput placeholder="Empresa (opcional)" formControlName="company" fullWidth>
          </div>
       
          <button nbButton hero status="primary" type="submit" [disabled]="!registerForm.valid">Registrar</button>
        </form>
        <button nbButton status="primary"  routerLink="/">Volver</button>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
</nb-layout>
