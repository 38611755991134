import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private url = environment.url + "/tasks";

  constructor(private http: HttpClient) { }

  private getHeaders() {
    const authToken = localStorage.getItem('token');
    return {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    };
  }

  // Método para crear una nueva tarea
  create(task: any): Observable<any> {
    return this.http.post(this.url, task, this.getHeaders());
  }

  // Método para editar una tarea existente
  update(taskId: string, updatedTask: any): Observable<any> {
    const editUrl = `${this.url}/${taskId}`;
    return this.http.put(editUrl, updatedTask, this.getHeaders());
  }

  // Método para eliminar una tarea
  delete(taskId: string): Observable<any> {
    const deleteUrl = `${this.url}/${taskId}`;
    return this.http.delete(deleteUrl, this.getHeaders());
  }

  // Método para obtener una tarea por su ID
  get(taskId: string): Observable<any> {
    const readUrl = `${this.url}/${taskId}`;
    return this.http.get(readUrl, this.getHeaders());
  }

  // Método para obtener tareas por fecha
  getMany(date: Date): Observable<any> {
    const formatDate = moment(date).format('yyyy-MM-DD');
    const getManyUrl = `${this.url}/byDate?date=${formatDate}`;

    return this.http.get(getManyUrl, this.getHeaders());
  }
}
